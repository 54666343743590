import { postFakeLogin } from "helpers/fakebackend_helper";
import { loginError, loginSuccess, logoutSuccess } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import { getFirebaseBackend } from "helpers/firebase_helper";
//import { getFirebaseDocuments } from "helpers/firebase_helper";

//import { db } from "helpers/firebase_helper"; // this is from you export an initialize the app
// import { collection, doc, getDocFromCache, getDocs } from "firebase/firestore";
// import { Firestore } from 'firebase/firestore';
// import { QuerySnapshot } from 'firebase/firestore';


interface User {
    email: string;
    password: string;
}



export const loginUser = (
    user: User,
    history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {
    try {
        let response: any;
        if (process.env.REACT_APP_DEFAULTAUTH === "fake") {

            response = await postFakeLogin({
                email: user.email,
                password: user.password,
            });

            localStorage.setItem("authUser", JSON.stringify(response));

        }
        else if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {

            console.log("=== y.z working with firebase == ");
            console.log("=== y.z email = " + user.email);
            console.log("=== y.z password = " + user.password);

            let fireBaseBackend = await getFirebaseBackend();

            response = await fireBaseBackend.loginUser(
                user.email,
                user.password
            )

            //const data =  fireBaseBackend.getExistingUsersFromFirestore();



            if (response) {

                // const uid = fireBaseBackend.auth().currentUser.uid;
                // const currentUserProfile = fireBaseBackend.firestore().collection("users").doc(uid);    
                const data = fireBaseBackend.getAuthenticatedUser();

                console.log("y.z currentUserProfile = " + data?.email);

                dispatch(loginSuccess(response));
                history("/dashboard");



            }

        }





    } catch (error) {

        dispatch(loginError(error));
    }
};

export const logoutUser = () => async (dispatch: Dispatch) => {
    try {
        localStorage.removeItem("authUser");

        let fireBaseBackend = await getFirebaseBackend();

        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const response = fireBaseBackend.logout;
            dispatch(logoutSuccess(response));

        } else {
            dispatch(logoutSuccess(true));
        }
    } catch (error) {
        dispatch(loginError(error));
    }
}


export const socialLogin = (type: any, history: any) => async (dispatch: any) => {
    try {
        let response: any;

        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const fireBaseBackend = getFirebaseBackend();
            response = fireBaseBackend.socialLoginUser(type);
        }

        const socialData = await response;

        if (socialData) {
            sessionStorage.setItem("authUser", JSON.stringify(socialData));
            dispatch(loginSuccess(socialData));
            history('/dashboard');
        }

    } catch (error) {
        dispatch(loginError(error));
    }
}


/*
async function fetchDataFromFirestore(): Promise<Array<{ id: string; [key: string]: any }>> {
    const querySnapshot = await getDocs(collection(db, "HygraphQuery"));

    const data: Array<{ id: string; [key: string]: any }> = [];
    querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
        console.log('y.z - doc: ' + doc.data);
    });
    return data;
}







export const useGetDocuments = () => {

  const getDoc = async (path: string): Promise<Array<{ [key: string]: any; id: string }>> => {
    const collectionRef = collection(db, path);
    const querySnapshot = await getDocs(collectionRef);
    const data = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return data;
  };

  return { getDoc };
};


  */